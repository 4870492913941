import { useEffect } from "react"
import img1 from "../../images/pexels-david-bartus-442116.jpg"
import img2 from "../../images/pexels-grape-things-2954924.jpg"
// import img3 from "../../images/pexels-pixabay-39511.jpg"
import img4 from "../../images/pexels-tim-mossholder-953166.jpg"
import video from "../../videos/6739346-hd_1920_1080_24fps.mp4"

export const Slider = () => {

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'auto'});
    }, [])


    return (

        <section className="slider">
            <div className="slider-box">
                <div className="slider-content">
                    <div className="slider-img">
                        <img className="slider-image" alt="slider1" src={img2}/>
                        <div className="slider-text">
                            <div className="content-textUno">
                                <p className="content-textEffect">TE TRAEMOS</p>
                            </div>
                            <div className="content-textDos">
                                <p className="content-textEffect delay">Una Experiencia</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="slider-content">
                    <div className="slider-img">
                        <img className="slider-image" alt="slider2" src={img4}/>
                        <div className="slider-text">
                            <div className="content-textUno">
                                <p className="content-textEffect">TE BRINDAMOS VINOS</p>
                            </div>
                            <div className="content-textDos">
                                <p className="content-textEffect delay">con Identidad</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="slider-content">
                    <div className="slider-img">
                        {/* <img className="slider-image" alt="slider3" src={img3}/> */}
                        <video className="slider-image" autoPlay loop muted playsInline>
                            <source src={video} type="video/mp4" />
                            Tu navegador no soporta el elemento de video.
                        </video>
                        <div className="slider-text">
                            <div className="content-textUno">
                                <p className="content-textEffect">IMPORTAMOS</p>
                            </div>
                            <div className="content-textDos">
                                <p className="content-textEffect delay">Historia</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="slider-content">
                    <div className="slider-img">
                        <img className="slider-image" alt="slider4" src={img1}/>
                        <div className="slider-text">
                            <div className="content-textUno">
                                <p className="content-textEffect">TE COMPARTIMOS</p>
                            </div>
                            <div className="content-textDos">
                                <p className="content-textEffect delay">Cultura y Orígenes</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
