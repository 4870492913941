import { useState, useEffect } from 'react';
import { Cargando } from '../Cargando/Cargando';
import { ItemList } from '../ItemList/ItemList';
import { baseDeDatos, storage } from '../Firebase/Config';
import { collection, getDocs } from 'firebase/firestore';
import { useCotDolar } from '../Api/useCotDolar';
import { FlyerEnvio } from '../Flyer/FlyerEnvio';
import { Sticky } from '../Sticky/Sticky';
import { getDownloadURL, listAll, ref } from 'firebase/storage';

export const ItemListContainer = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });

    const [productos, setProductos] = useState([]);
    const [productosOriginales, setProductosOriginales] = useState([]);
    const [imagenes, setImagenes] = useState([]);
    const [loading, setLoading] = useState(false);
    const { dolarOficial } = useCotDolar(0);
    const [filtros, setFiltros] = useState({ bodega: '', tipo: '' });

    useEffect(() => {
        document.title = "Productos - JB Premium - Vinos Españoles - Distribuidor Oficial";
        setLoading(true);

        const productosBaseDeDatos = collection(baseDeDatos, 'productos');

        getDocs(productosBaseDeDatos)
            .then((resp) => {
                const fetchedProductos = resp.docs.map((item) => ({
                    id: item.id,
                    precioDolar: dolarOficial,
                    ...item.data(),
                }));
                setProductos(fetchedProductos);
                setProductosOriginales(fetchedProductos);

                const storageRef = ref(storage, '/');
                listAll(storageRef)
                    .then(async (result) => {
                        const promises = result.items.map(async (item) => {
                            const ubi = await getDownloadURL(item);
                            return { ubi, nam: item.name };
                        });
                        const urls = await Promise.all(promises);
                        setImagenes(urls);
                    })
                    .catch((error) => {
                        console.error('Error al obtener imágenes:', error);
                    });
            })
            .finally(() => {
                setLoading(false);
            });
    }, [dolarOficial]);

    useEffect(() => {
        const { bodega, tipo } = filtros;

        const productosFiltrados = productosOriginales.filter((producto) => {
            const matchBodega = bodega ? producto.bodega === bodega : true;
            const matchTipo = tipo ? producto.vino === tipo : true;
            return matchBodega && matchTipo;
        });

        setProductos(productosFiltrados);
    }, [filtros, productosOriginales]);

    const handleInputChange = (e) => {
        setFiltros({
            ...filtros,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <>
            <main className="main">
                <p className="main-title">NUESTROS PRODUCTOS</p>
                <hr />
                <div style={{ display: 'flex', flexWrap: "wrap", justifyContent: 'space-evenly', width: '100%', backgroundColor: "#dbcb92", margin: '3rem 0 2rem' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                        <label style={{ margin: '1rem 0 .5rem', fontFamily: 'Roboto, sans-serif' }}>Filtra por Bodega</label>
                        <select
                            className="form-input"
                            placeholder="Elige una opción"
                            name="bodega"
                            onChange={handleInputChange}
                        >
                            <option value="">Elige tu opción</option>
                            <option value="Protos">Protos</option>
                            <option value="Herederos de Marqués de Riscal">Marqués del Riscal</option>
                            <option value="Martín Códax">Martín Códax</option>
                            <option value="Príncipe de Viana">Príncipe de Viana</option>
                            <option value="Clunia">Clunia</option>
                        </select>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                        <label style={{ margin: '1rem 0 .5rem', fontFamily: 'Roboto, sans-serif' }}>Filtra por Tipo de Vino</label>
                        <select
                            className="form-input"
                            placeholder="Elige una opción"
                            name="tipo"
                            onChange={handleInputChange}
                        >
                            <option value="">Elige tu opción</option>
                            <option value="tinto">Vino Tinto</option>
                            <option value="blanco">Vino Blanco</option>
                            <option value="rosado">Vino Rosado</option>
                            <option value="espumoso">Vino Espumante</option>
                        </select>
                    </div>
                </div>
                {loading ? <Cargando /> : <ItemList productos={productos} imagenes={imagenes} />}
            </main>
            <FlyerEnvio />
            <Sticky />
        </>
    );
};
