import { useEffect } from "react"
import { FlyerEnvio } from "../Flyer/FlyerEnvio"
import { Sticky } from "../Sticky/Sticky"
import video from "../../videos/5537824-uhd_2160_4096_25fps.mp4"

export const Nosotros = () => {

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'auto'});
    document.title = "Nosotros - JB Premium - Vinos Españoles - Distribuidor Oficial"
  }, [])

  return (
    <>
      <main className="main">
        <p className='main-title'>NOSOTROS</p>
        <div className="nosotros-content">
          <p className="nosotros-desc nosotros-width">JB Premium es una empresa localizada en Argentina, Buenos aires, especializada en la comercialización y distribución de vinos españoles. Contamos con 10 años de experiencia en la importación, representando con exclusividad a bodegas líderes y con historia en esta categoría siendo pioneras a lo largo de la historia.<br/>Desarrollamos nuestra actividad a través de múltiples canales comerciales: mayoristas, restaurantes, vinotecas, y amantes del vino.</p>
          <div className="nosotros-contentBox">
            <div className="nosotros-contentDesc">
              <p className="nosotros-title">NUESTRA MISIÓN</p>
              <p className="nosotros-desc">Nuestro objetivo principal es proveer productos de calidad, con raíces e historia y ponerlos al alcance del consumidor Argentino pasando por la experiencia del viejo continente en su mesa y traerles el mayor tesoro vinícola del mundo.
              </p>
            </div>
            <div className="nosotros-contentImg">
              <img className="nosotros-img" src="/img/pexels-pixabay-262353.jpg" alt="nosotros1"/>
            </div>
          </div>
          <div className="nosotros-contentBox reverse">
            <div className="nosotros-contentImg">
              <img className="nosotros-imgWidth" src="/img/D69E6CAA-F7E9-4BF3-A60F-F218EF572AD4.jpg" alt="nosotros2"/>
            </div>
            <div className="nosotros-contentDesc">
              <p className="nosotros-title">NUESTRO VALOR</p>
              <p className="nosotros-desc">Nos apasiona la comercialización, los vinos que representamos y el éxito compartido, Respetando todos los procesos, valorando la tierra, la gente y la cultura española.
              </p>
            </div>
          </div>
          <div className="nosotros-contentBox">
            <div className="nosotros-contentDesc">
              <p className="nosotros-title">NUESTRO DESAFÍO</p>
              <p className="nosotros-desc">Nuestra filosofía de negocios es ofrecer un alto nivel de vinos y al mejor precio posible, dándonos a conocer en toda la Argentina, y puedan pasar por una experiencia única.
              </p>
            </div>
            <div className="nosotros-contentImg">
              {/* <img className="nosotros-imgWidth" src="/img/803C2501-9C78-4CD2-A485-519A37F7F22F.jpg" alt="nosotros1"/> */}
              <video className="nosotros-imgWidth video" autoPlay loop muted playsInline>
                <source src={video} type="video/mp4" />
                Tu navegador no soporta el elemento de video.
              </video>
            </div>
          </div>
        </div>
      </main>
      <FlyerEnvio/>
      <Sticky/>
    </>
  )
}
